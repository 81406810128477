import { queryContentfulPages } from 'clients/contentful/contentfulPages';
import { Page } from 'components/Page';
import { PageProps } from 'components/Page/Page';
import { loadContentfulGenericData } from 'lib/contentful/loadContentfulData';
import { arrayToRelativePath, relativePathToArray } from 'lib/relativePaths';
import { GetStaticPaths, GetStaticProps } from 'next';
import { truckMapConfig } from 'truckMapConfig';

type PageURLParams = {
  relativePath: string[];
};

const localesFallback = [truckMapConfig.i18n.defaultLocale];

const fastDevBuild = truckMapConfig.env === 'development';

export const getStaticPaths: GetStaticPaths<PageURLParams> = async ({ locales }) => {
  const pageEntries = !fastDevBuild ? await queryContentfulPages() : [];

  return {
    fallback: !fastDevBuild ? false : 'blocking',
    paths: pageEntries
      .map((relativePath) => {
        const normalizedPath = relativePathToArray(relativePath);

        return (locales ?? localesFallback).map((locale) => ({
          locale: locales ? locale : undefined,
          params: {
            relativePath: normalizedPath
          }
        }));
      })
      .flat()
  };
};

export const getStaticProps: GetStaticProps<PageProps, PageURLParams> = async ({
  params,
  locale = truckMapConfig.i18n.defaultLocale
}) => {
  const relativePath = params?.relativePath || [];

  try {
    const legacyRelativePath = arrayToRelativePath(relativePath);

    console.log('>>>> Fetching contentful layout', legacyRelativePath);

    const {
      contentfulQuery,
      legacyLayoutResponse: [page]
    } = await loadContentfulGenericData({
      legacyRelativePath
    });

    return {
      props: { page, contentfulQuery }
    };
  } catch (error) {
    console.log('static page error', { error, relativePath, locale });

    return {
      notFound: true
    };
  }
};

export default Page;
